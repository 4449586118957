/* The main div that contains the entire menu */
#_desktop_top_menu {
  position: relative; /* This is our reference for the absolute positioning of the sub-menus */
}

/* Styles for the depth 0 menu items */
.top-menu[data-depth="0"] {
  width: 100%;
  padding: 15px 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
  align-content: flex-start;
}

.top-menu[data-depth="0"] > li > a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

/* Styles for the depth 1 sub-menus */
.sub-menu[data-depth="1"] {
  display: none;
  position: absolute; /* Positioning relative to #_desktop_top_menu */
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1000;
  background-color: #ffeae5;
  padding: 2rem 4rem 15px 2rem;
  border-radius: 11px;
}

.top-menu[data-depth="0"] > li:hover > .sub-menu {
  display: flex;
  flex-flow: row wrap;
  flex-direction: column !important;
}

.sub-menu[data-depth="1"] > li {
  margin: 0;
  white-space: nowrap; /* Prevents breaking the menu items to the next line */
  flex-basis: 21%;
}

.sub-menu[data-depth="1"] > li > a {
  color: #fff;
  font-size: 1rem;
  display: inline-block;
  padding-bottom: 10px;
  font-family: "Chakra Petch", sans-serif;
}

.sub-menu[data-depth="1"] > li > a:after {
  content: '';
  display: block;
  width: 15px;
  height: 2px;
  background: #7a7a7a;
  margin: 15px 0 10px 0;
}


.top-menu[data-depth="0"] > li:hover > .sub-menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}


.top-menu a:hover {
  color: $gray !important;
}



.sub-menu[data-depth="1"] > li:hover > .sub-menu[data-depth="2"] {
  display: block; /* Show sub-sub-menu on hover over a depth 1 item */
}

.sub-menu[data-depth="2"] {
  display: none;
  background: white;
  padding: 20px;
  border-radius: 11px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
  flex-direction: column !important;
  flex-wrap: wrap !important;
  position: absolute;
  left: 100%;
  top: -10px;
}


a.dropdown-submenu:hover+.sub-menu[data-depth="2"],
.sub-menu[data-depth="2"]:hover,
.sub-menu > li:hover > .sub-menu[data-depth="2"] {
  display: flex !important;
}

.sub-menu[data-depth="2"] > li {
  margin: 0;
  padding: 3px;
  white-space: nowrap; /* Prevents breaking the menu items to the next line */
}

.sub-menu[data-depth="2"] > li > a {
  color: #333; /* Text color for depth 2 items */
  font-size: 0.8rem;
  text-decoration: none;
  font-family: "Chakra Petch", sans-serif;
}

.sub-menu[data-depth="1"] > li {
  position: relative;
}