div.row.user-profile {
  .links {
    span.link-item {
      background: #ffeae5 !important;
      border-radius: 6px !important;
      color: #7a7a7a !important;
      font-family: 'Chakra Petch', sans-serif !important;

      i {
        color: #7a7a7a !important;
      }

      &:hover {
        color: #f39d72 !important;

        i {
          color: #f39d72 !important;
          font-weight: bold !important;
        }
      }
    }
  }
}

.wishlist-list-item:hover .wishlist-list-item-title {
  color: inherit !important;
}

a.wishlist-add-to-new.btn.btn-secondary:hover {
    color: #0a0a0a !important;
}

li.wishlist-list-item:nth-child(odd) {
  background-color: #ffeae5;
}
