.newsletter-block-area {
  background-color: $brand-primary;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding-top: 2rem;
  margin-bottom: 4rem;

  .row {
    background-color: $brand-primary;
  }

  .newsletter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .block_newsletter {
    max-width: unset;
    float: unset;
    flex-basis: 33%;
  }

  .logo-newsletter {
    flex-basis: 33%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    img {
      max-width: 8rem;
    }
  }


  .social-links-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -2rem;
    flex-basis: 33%;
  }
}
