/**
 * Website top.
 */
#mobile-logo {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-align: center;
  }

  img.logo {
    max-width: 60%;
    margin-top: 4.4rem;
  }
}

#mobile-top-nav {
  padding: 1.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  & > div {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    -ms-flex-preferred-size: 33%;
    flex-basis: 33%;
    height: 4rem;
    /* font-size: 2rem; */
    display: flex;
    align-items: center;
    text-align: center;
    background: #ffeae5;
    justify-content: center;
  }
}


@include media-breakpoint-down(sm) {
  #footer {
    padding-top: 0;
    .blockreassurance.col-sm-12 {
      margin: 0 auto;
    }

    .blockreassurance.col-sm-12 .block-title {
      margin-top: 0;
    }

    div.blockreassurance {
      .row {
        display: flex;
        flex-wrap: wrap;

        & > div {
          flex-basis: 50%;
          margin-bottom: 2rem;
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          text-align: center;

          & > div, & > p {
            margin-top: 15px;
          }
        }
      }

      .block-icon {
        height: auto;
      }

      .block-title {

      }

      .block-icon, .block-icon img {
        width: auto !important;
      }
    }

    // Block newsletter on footer.
    .newsletter-block-area {
      .logo-newsletter {
        display: none;
      }

      .block_newsletter {
        background-color: #ffeae5;
        padding: 0;
      }
    }

    .newsletter-block-area .block_newsletter {
      flex-basis: 100%;
      padding: 2rem;
    }
  }
}

@media screen and (max-width: 488px){
  #footer {

    div.blockreassurance {
      .row > div {
          & > p {
            display: none;
          }
      }
    }
  }
}

/**
 * Product page.
 */
#product #content {
  @include media-breakpoint-down(sm) {
    max-width: 320px;

    .js-qv-mask.mask {
      display: none;
    }
  }
}

.product-actions .control-label, #product-availability {
  @include media-breakpoint-down(sm) {
      display: none;
  }
}


.product-actions .product-add-to-cart {
  @include media-breakpoint-down(sm) {
    position: fixed;
    bottom: 0;
    background: white;
    z-index: 9999;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    padding-top: 15px;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }
}

#mollie-applepay-direct-button {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.product-quantity {
  @include media-breakpoint-down(sm) {
    &::after {
      display: none;
    }
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}


.product-additional-info {
  @include media-breakpoint-down(sm) {
    display: flex;
    align-items: baseline;
  }
}


.nav-tabs .nav-link {
  @include media-breakpoint-down(sm) {
    padding: 0.4em 0.4em;
  }
}


div.user-info a.account i.material-icons {
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}


.ph-products-tabs {
  @include media-breakpoint-down(sm) {
    margin-top: 8rem;
  }
}

.product-additional-info #loyalty {
  @include media-breakpoint-down(sm) {
    display: block;
    position: absolute;
    top: 100%;
    max-width: 94%;
    border-radius: 6px;
  }
}

#_mobile_logo {
  @include media-breakpoint-down(sm) {
    margin: 0 auto;
    width: 100%;
    a {
      margin: 0 auto;
    }
  }
}
