#manufacturer {
  #main {
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      .brand {
        position: relative;
        width: calc(20% - 10px);
        min-width: 210px;
        padding: 10px;
        margin: 5px;
        text-align: center;
        transition: 0.4s ease-out;
        line-height: 2rem;

        &-infos {
          a {
            display: inline-block;
          }
        }

        &-products {
          display: none;
        }

        &-img {
          position: relative;
          width: 200px;
          margin: auto;

          a {
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              width: 100%;
              height: 100%;
              content: "";
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #manufacturer {
    #main {
      ul {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
      }
    }
  }
}

.brand-letter-title {
  font-size: 2.5rem;
  font-family: 'Chakra Petch', sans-serif;
  margin-bottom: 15px;
  border-top: 1px solid #000000;
  padding-top: 15px;
  font-weight: 400;
}

.brand-by-title {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-top: 4rem;

  .brand-group {
    width: 30%;
    margin-top: 2rem;
    margin-bottom: 4rem;

    h2 {
      text-align: center;
      width: 100%;
    }

    li, a {
      text-align: center;
      width: 100%;
      color: #626161;
      font-family: 'Chakra Petch', sans-serif;
      font-size: larger;
    }

    a:hover {
      color: $brand-secondary;
      text-decoration: underline;
    }

    .brand-title {
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
}
