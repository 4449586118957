@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* stylelint-disable */
@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/subcategories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/newsletter";
@import "components/brands";
@import "components/suppliers";
@import "components/header";
@import "components/wrapper";
@import "components/main";
@import "components/notifications";
@import "components/progressbar";
@import "components/buttons";
@import "partials/bootstrap_overrides";
@import "partials/modules";
@import "components/phloeme-menu";
@import "components/newsletter-block-area";
@import "pages/user-profile";
@import "partials/responsive";
/* stylelint-enable */

ul.page-list > li > span.spacer {
  vertical-align: super;
  margin-right: 8px;
}