.quickview {
  .modal-dialog {
    width: calc(100% - 30px);
    max-width: 64rem;
  }

  .modal-content {
    width: 100%;
    min-height: 28.13rem;
    background: #ffeae5;
    border-radius: 6px;

    p {
      background-color: #ffeae5 !important;
    }

    button {
      background-color: #f6f6f6;
    }

    button:hover {
      color: darken(#f6f6f6, 20%);
    }
  }

  .modal-header {
    padding: $small-space;
    border: none;
  }

  .modal-body {
    min-height: 23.75rem;
  }

  .modal-footer {
    border-top: 1px solid rgba($gray, 0.3);
  }

  .layer {
    display: none;
  }

  .product-cover img {
    width: 100%;
    height: auto;
  }

  .images-container {
    z-index: 1;
    display: flex;
    min-height: 21.88rem;

    .product-images > li.thumb-container .thumb {
      width: 100%;
      max-width: 4.938rem;
      height: auto;
      margin-bottom: 0.8125rem;
      background: #ffeae5;
    }
  }

  .mask {
    width: 35%;
    max-height: 22.5rem;
    margin-left: $small-space;
    overflow: hidden;
  }

  .arrows {
    position: absolute;
    top: 0;
    right: 5rem;
    bottom: 0;
    z-index: 0;
    max-height: 22.5rem;

    .arrow-up {
      margin-top: -3.125rem;
      cursor: pointer;
      opacity: 0.2;
    }

    .arrow-down {
      position: absolute;
      bottom: -1.875rem;
      cursor: pointer;
    }
  }

  .social-sharing {
    margin-top: 0;
    margin-left: $extra-large-space;
  }
}
