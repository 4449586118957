// Shopping cart icon.
.ph-shopping-cart-icon,
.ph-customer-sign-in-icon {
  height: auto;
  margin-right: 7px;
}

.ph-shopping-cart-icon:hover {
  filter: invert(64%) sepia(56%) saturate(368%) hue-rotate(333deg) brightness(95%) contrast(101%);
}

.ph-shopping-cart-icon:hover,
.ph-customer-sign-in-icon:hover {
  opacity: 0.7;
  cursor: pointer;
}

.ph-shopping-cart-icon {
  width: 30px;
}

.ph-customer-sign-in-icon {
  width: 25px;
  margin-left: 10px;
}

.cart-products-count {
  margin-left: -6px;
  vertical-align: bottom;
}


.brands-sort .select-title, .products-sort-order .select-title, .suppliers-sort .select-title {
  border: 1px solid rgba(0,0,0,.25);
  background: #fafafa;
  border-radius: 6px;
}

.btn {
  border-radius: 6px !important;
}


// Wishlist module
.wishlist-button-add {
  background-color: #ffeae5 !important;
}


// User login on top banner right.
div.user-info {
  a.account {
    span {
      vertical-align: middle;
    }

    i.material-icons {
      font-size: 30px;
      margin-left: 1.2rem;
    }
  }
}


/**
 * Article page.
 */
article.product-miniature button.wishlist-button-add {
  display: none;
}


.brands-sort .select-list:hover, .products-sort-order .select-list:hover, .suppliers-sort .select-list:hover {
  color: inherit !important;
}


.blockreassurance.col-sm-12 {
  margin-bottom: 5rem;

  .block-title {
    margin-top: 1rem;
  }

  .block-icon {
    img {
      height: 90px !important;
    }
  }

}

.blockreassurance img {
  filter: invert(64%) sepia(56%) saturate(368%) hue-rotate(333deg) brightness(95%) contrast(101%);
  padding-bottom: 1rem;
}



.tabs .nav-tabs .nav-link.active {
  color: $brand-secondary;
}


.ph-products-tabs {
  a.nav-link {
    text-transform: uppercase !important;
    font-family: "Chakra Petch", sans-serif !important;
  }

  p, span {
    color: #292828 !important;
  }

  img {
    max-height: 100px;
    width: auto;
    margin-bottom: 2rem;
  }

  background-color: $brand-primary;
  border-radius: 6px;

  p.p1, span {
    background-color: unset !important;
  }
}


p {
  letter-spacing: 0.03rem !important;
}


.product-container.js-product-container h1 {
  text-align: initial;
}


a:hover {
  color: #f39d72 !important;
}


/**
 * Checkout process
 */
.product-line-actions > div.row {
  display: flex;

  input[type='number'] {
    pointer-events: none;
  }
}


.blockreassurance_product {

  border-radius: 6px;
  padding: 1.5rem;
  background-color: #ffeae5;

  .block-title {
    font-weight: 700;
  }

  .item-product img {
    height: auto !important;
    filter: invert(64%) sepia(56%) saturate(368%) hue-rotate(333deg) brightness(95%) contrast(101%);
  }
}

.step-number {
  background-color: #f39d72 !important;
}


/**
 * Right banner
 */
div.display-banner-right {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 8%;
}

div.header-banner div.social-links-banner {
  margin-right: 15rem;
}


.sticky {
  position: fixed !important;
  top: 2.6rem;
  width: 100%;
  z-index: 1000;
  background: white;
  margin-top: -2px;
  box-shadow: rgba(0,0,0,.1) 0 1px 3px 0,rgba(0,0,0,.06) 0 1px 2px 0;
  height: 3rem;

  ul {
    justify-content: center !important;
  }
}


/**
 * Checkout process
 */
body#checkout section.checkout-step .payment-options .payment-option {
  margin-bottom: .5rem;

  label {
    display: flex;
    flex-flow: row-reverse;
    justify-content: flex-end;
    margin: 0;
    align-items: center;

    img {
      width: 2.5rem;
      margin-right: 0.6rem;
    }
  }
}

body#checkout section.checkout-step .payment-options .payment-option {
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

body#checkout .condition-label {
  padding-left: 2rem;
  margin-top: 3.8rem;
}


.block-promo .promo-code-button.cancel-promo[aria-expanded=true] {
  color: #3d3d3d;
}

.promo-code form {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  button {
    margin-top: 1rem !important;
  }

  input.promo-input {
    width: 100%;
  }
}

.block-promo .promo-input+button {
  margin-top: 1rem !important;
}


#mondialrelay_change-relay {
  margin-bottom: 0.8rem;
  margin-top: 0.6rem;
}


.modal.in .modal-dialog {
  max-width: 950px;
}


#conditions-to-approve ul li div {
  padding: 1rem;
  background-color: #ffeae5;
  border-radius: 6px;

  label {
    margin-left: 1rem;
    font-weight: bold;
    font-size: 1rem;
  }
}


#blockcart-modal .product-name {
  font-size: 1.125rem;
  color: inherit !important;
}


label.btn.btn-default {
  color: grey !important;
}

.product-accessories {
  margin-top: 4rem;
}


.product-description ul {
  list-style: initial;
  margin-left: 2rem;
  line-height: 2rem;
}
